<template>
  <div class="iq-sidebar shadow-lg sidebar-default">
    <div id="sidebar-scrollbar">
      <nav class="iq-sidebar-menu">
        <ul id="iq-sidebar-toggle" class="iq-menu">
          <li :class="checkActive('social.list') ? 'active' : ''">
            <router-link :to="{ name: 'social.list' }" class=" ">
              <i class="las la-home"></i><span>{{ $t('menu.home') }}</span>
            </router-link>
          </li>
          <!-- <li :class="checkActive('socialRecommend.list') ? 'active' : ''">
            <router-link :to="{
                name: 'socialRecommend.list',
              }" class=" ">
              <i class="las la-thumbs-up"></i><span>{{ $t('menu.recommend') }}</span>
            </router-link>
          </li> -->
          <li :class="checkActive('social.subscription') ? 'active' : ''">
            <router-link :to="{
                name: 'social.subscription',
              }" class=" ">
              <i class="las la-check-circle"></i><span>{{ $t('menu.subscription') }}</span>
            </router-link>
          </li>
          <li :class="checkActive('social.follow') ? 'active' : ''">
            <router-link :to="{ name: 'social.follow' }" class=" ">
              <i class="las la-user-plus"></i><span>{{ $t('menu.track') }}</span>
            </router-link>
          </li>
          <li :class="checkActive('social.notification') ? 'active' : ''">
            <router-link :to="{ name: 'social.notification' }" class=" ">
              <i class="las la-bell"></i><span>{{ $t('menu.notice') }}</span>
            </router-link>
            <div class="unread btn-primary"><span>{{ user.notify_unread }}</span></div>
          </li>
          <!-- 自定義名單 -->
          <!-- <li v-if="checkPermission('socialTagList.list')" :class="checkActive('socialTagList.lists') ? 'active' : ''">
            <router-link :to="{ name: 'socialTagList.lists' }" class=" ">
              <i class="las la-list-alt"></i><span>{{ $t('menu.list') }}</span>
            </router-link>
          </li> -->
          <li :class="checkActive('app.chat') ? 'active' : ''">
            <router-link :to="{ name: 'app.chat' }" class=" ">
              <i class="lab la-rocketchat"></i><span>{{ $t('menu.privateMessage') }}</span>
            </router-link>
            <div class="unread btn-primary"><span>{{ msgUnread }}</span></div>
          </li>
          <li v-if="checkPermission('social.profileimage')" :class="checkActive('social.profileimage') ? 'active' : ''">
            <router-link :to="{ name: 'social.profileimage' }" class=" ">
              <i class="las la-image"></i><span>{{ $t('menu.mediaLibrary') }}</span>
            </router-link>
          </li>
          <li v-if="checkPermission('app.calendar')" :class="checkActive('app.calendar') ? 'active' : ''">
            <router-link :to="{ name: 'app.calendar' }" class=" ">
              <i class="las la-calendar"></i><span>{{ $t('menu.postRegularly') }}</span>
            </router-link>
          </li>
          <li v-if="checkPermission('social.add-bank')" :class="checkActive('social.add-bank') ? 'active' : ''">
            <router-link :to="{ name: 'social.add-bank' }" class=" ">
              <i class="las la-university"></i><span>{{ $t('menu.addBank') }}</span>
            </router-link>
          </li>
          <li :class="checkActive('social.add-card') ? 'active' : ''">
            <router-link :to="{ name: 'social.add-card' }" class=" ">
              <i class="las la-credit-card"></i><span>{{ $t('menu.addCreditCard') }}</span>
            </router-link>
          </li>
          <li :class="checkActive('social.profilemain') ? 'active' : ''">
            <router-link :to="{ name: 'social.profilemain' }" class=" ">
              <i class="las la-user"></i><span>{{ $t('menu.myPersonalInformation') }}</span>
            </router-link>
          </li>
          <li :class="checkActive('user.profile-edit') ? 'active' : ''">
            <router-link :to="{ name: 'user.profile-edit' }"><i class="ri-grid-line"></i>{{ $t('menu.profile') }}
            </router-link>
          </li>
          <li v-if="checkPermission('user.goods')" :class="checkActive('user.goods') ? 'active' : ''">
            <router-link :to="{ name: 'user.goods' }"><i class="ri-store-line"></i>{{ $t('menu.goods') }}</router-link>
          </li>
          <li v-if="checkPermission('user.order-buy')" :class="checkActive('user.order-buy') || checkActive('user.order-sale') ? 'active' : ''">
            <router-link :to="{ name: 'user.order-buy' }"><i class="ri-file-list-3-line"></i>{{ $t('menu.order') }}</router-link>
          </li>
          <li v-if="checkPermission('user.earnings')" :class="checkActive('user.earnings') ? 'active' : ''">
            <router-link :to="{ name: 'user.earnings' }"><i class="ri-list-check-2"></i>{{ $t('menu.analysisOfIncome') }}</router-link>
          </li>
          <li v-if="checkPermission('user.other-setting')" :class="checkActive('user.other-setting') ? 'active' : ''">
            <router-link :to="{ name: 'user.other-setting' }"><i class="ri-information-line"></i>
              {{ $t('menu.otherSettings') }}</router-link>
          </li>
          <li>
            <router-link v-on:click.enter="signOut" :to="{ name: 'auth1.sign-in' }">
              <i class="ri-external-link-line"></i>{{ $t('登出') }}
            </router-link>
          </li>
        </ul>
      </nav>
      <div class="p-5"></div>
    </div>
  </div>
  <div class="float_right_wrap" @click="removeSideBar"></div>
</template>
<script>
import Scrollbar from 'smooth-scrollbar'
import { mapGetters } from 'vuex'
import { removeLocalStorageClient } from '@/utils/assist'
export default {
  name: 'DefaultSidebar',
  computed: {
    ...mapGetters({
      user: 'User/userState',
      token: 'User/tokenState',
      msgUnread: 'User/unReadNumState'
    })
  },
  data() {
    return {
      blogs: ['blog.blog-grid', 'blog.blog-list', 'blog.blog-detail'],
      stores: [
        'store.category-grid',
        'store.category-list',
        'store.store-detail',
        'store.checkout'
      ],
      emails: ['app.emaillisting', 'app.composebody'],
      pages: [],
      auths: [
        'extra-pages',
        'auth1.sign-in',
        'auth1.sign-up',
        'auth1.recoverpassword1',
        'auth1.confirmmail1',
        'auth1.lockscreen'
      ],
      extrapages: [
        'extra-pages.timelines',
        'extra-pages.invoice',
        'extra-pages.blank-page',
        'default.error404',
        'default.error500',
        'extra-pages.pricing',
        'extra-pages.pricing-1',
        'default.maintenance',
        'default.coming-soon',
        'extra-pages.faq'
      ],
      uielement: [],
      uikit: [
        'core.color',
        'core.typography',
        'core.alert',
        'core.badges',
        'core.breadcrumb',
        'core.button',
        'core.cards',
        'core.carousel',
        'core.embed-video',
        'core.grid',
        'core.images',
        'core.listgroup',
        'core.modal',
        'core.notifications',
        'core.pagination',
        'core.popovers',
        'core.progressbars',
        'core.tabs',
        'core.tooltips'
      ],
      forms: [
        'form.layout',
        'form.validate',
        'form.switch',
        'form.checkbox',
        'form.radio'
      ],
      formwizard: [
        'form-wizard.simple',
        'form-wizard.validatewizard',
        'form-wizard.vertical'
      ],
      tables: ['table.basic', 'table.editable', 'table.datatable'],
      icons: ['icon.fontawesome-5', 'icon.lineawesome', 'icon.remixicon']
    }
  },
  methods: {
    signOut() {
      removeLocalStorageClient('user')
      removeLocalStorageClient('token')
      // this.$router.push({ name: 'auth1.sign-in' })
      location.reload()
    },
    checkPermission(route) {
      // 1一般會員 2 創作者
      if (route && this.user.user_type === 2) {
        return true
      }
    },
    checkActive(route) {
      if (this.$route.name === route) {
        return true
      }
      if (route.includes(this.$route.name)) {
        // eslint-disable-next-line no-debugger
        debugger
        return true
      }
    },
    SmoothScrollbar() {
      const elementExistMain = this.checkElement('id', 'sidebar-scrollbar')
      if (elementExistMain) {
        Scrollbar.init(document.querySelector('#sidebar-scrollbar'))
      }

      const elementExistRight = this.checkElement(
        'id',
        'right-sidebar-scrollbar'
      )
      if (elementExistRight) {
        Scrollbar.init(document.querySelector('#right-sidebar-scrollbar'))
      }
    },
    removeSideBar() {
      this.$emit('closeSidebar');
    }
  }
}
</script>

<style scoped>
.sidebar-main .float_right_wrap {
  position: fixed;
  top: 4em;
  left: 12em;
  /* background: #000; */
  height: 100%;
  width: 100%;
  z-index: 1;
}
.unread {
  position: absolute;
  border-radius: 50%;
  min-width: 15px;
  min-height: 15px;
  font-size: 10px;
  left: 25px;
  top: 8px;
  text-align: center;
  line-height: 1;
  box-sizing: content-box;
  white-space: nowrap;
  display: inline-block;
}
.unread:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  padding-top: 100%;
  height: 0;
}
.unread span {
  display: inline-block;
  vertical-align: middle;
}
</style>
